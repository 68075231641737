<template>
  <div>
    <div class="row">
      <div class="col-3">
        <b-form-group label="Tipo de Entrega">
          <v-select
            :options="['BALCAO', 'BUSCAR', 'ENTREGA','MESA']"
            multiple
            v-model="filtros.tipo_entrega"
            :reduce="(it) => it.toUpperCase()"
          />
        </b-form-group>
      </div>
      <div class="col-3">
        <b-form-group label="Tipo de Preço">
          <v-select
            :options="tiposPreco"
            label="tipo"
            multiple
            v-model="filtros.tipo_preco"
            :reduce="(it) => it.cod_tipo"
          />
        </b-form-group>
      </div>
      <div class="col-3">
        <b-form-group label="Produto">
          <v-select
            :options="produtos"
            label="nome"
            multiple
            v-model="filtros.produtos"
            :reduce="(it) => it.cod_produto"
          />
        </b-form-group>
      </div>
      <div class="col-3">
        <b-form-group label="Tipo de Resumo">
          <b-select
            :options="[
              { text: 'Quantidade', value: 'qtd' },
              { text: 'Valor', value: 'vl_unitario' },
            ]"
            v-model="tipoResumo"
          />
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <hr />
        <pivot
          :data="list"
          :default-show-settings="false"
          hide-settings-text="Fechar Configurações"
          show-settings-text="Configurações"
          no-data-warning-text="Nenhum produto encontrado"
          :fields="campos"
          :available-field-keys="[
            'GrupoProduto',
            'Dia',
            'Mes',
            'Ano',
            'Empresa',
            'Tamanho',
          ]"
          :row-field-keys="['Produto']"
          :col-field-keys="['Entrega']"
          :reducer="(sum, item) => sum + item[tipoResumo]"
          :reducer-initial-value="0"
          :loading="loading"
          :computing="loading"
          :showFooter="true"
          :is-data-loading="loading"
          rows-label-text="Linhas"
          cols-label-text="Colunas"
          available-fields-label-text="Campos Disponíveis"
          
        >
          <!-- Optional slots can be used for formatting table headers and values, see documentation below -->
        </pivot>
      </div>
    </div>
  </div>
</template>

<script>
import ProdutoTipoPrecosLib from "../../libs/ProdutoTipoPrecosLib";
import PedidoLib from "../../libs/Pedido";
import moment from "moment";
const agrupador = (lista, campoGrupo, campoQtd, campoValor) => {
  return lista.reduce((ret, vl) => {
    let has = ret.find((x) => x[campoGrupo] == vl[campoGrupo]);
    if (!has) {
      ret.push({
        [campoGrupo]: vl[campoGrupo],
        qtd: vl[campoQtd],
        valor: vl[campoValor],
        lista: [vl],
      });
    } else {
      has.qtd += vl[campoQtd];
      has.valor += vl[campoValor];
      has.lista.push(vl);
    }

    return ret;
  }, []);
};
import Pivot from "@marketconnect/vue-pivot-table";
import ProdutoLib from "../../libs/ProdutoLib";
export default {
  components: {
    Pivot,
  },
  props: {
    empresaSelecionada: Array,
    dateRange: Object,
  },
  mounted() {
    (async () => {
      this.tiposPreco = await ProdutoTipoPrecosLib.get();
      this.produtos = await ProdutoLib.get();
      console.log(this.produtos);
    })();

    this.carregar();
  },
  data() {
    return {
      tipoResumo: "qtd",
      produtos: [],
      tiposPreco: [],
      list: [],
      filtros: {
        tipo_entrega: [],
        tipo_preco: [],
        produtos: [],
      },
      resumo: [],
      tiposPrecoHave: [],
      tiposEntregaHave: [],
      loading: false,
      camposDisponiveis: [
        {
          key: "GrupoProduto",
          getter: (item) => item.Grupo,
          label: "Grupo de Produto",
        },
      ],

      campos: [
        {
          key: "Produto",
          getter: (item) => item.Produto,
          label: "Produto",
          valueFilter: true,
          sort: (item) => item.qtd
        },
        {
          key: "Entrega",
          getter: (item) => item.tipo_entrega,
          label: "Tipo de Entrega",
          valueFilter: true,
        },
        {
          key: "GrupoProduto",
          getter: (item) => item.Grupo,
          label: "Grupo de Produto",
          valueFilter: true,
        },
        {
          key: "TipoPreco",
          getter: (item) => item.tipo,
          label: "Tamanho",
          valueFilter: true,
        },
        {
          key: "Empresa",
          getter: (item) => item.empresa,
          label: "Empresa",
          valueFilter: true,
        },
        {
          key: "Tamanho",
          getter: (item) => item.tipo,
          label: "Tamanho",
          valueFilter: true,
          showFooter: true,
        },

        {
          key: "Dia",
          getter: (item) => moment(item.data).format("DD/MM/YYYY"),
          label: "Dia",
          valueFilter: true,
        },
        {
          key: "Mes",
          getter: (item) => moment(item.data).format("MMM/YYYY"),
          label: "Mes",
          valueFilter: true,
        },
        {
          key: "Ano",
          getter: (item) => moment(item.data).format("YYYY"),
          label: "Ano",
          valueFilter: true,
        },
      ],
    };
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
    filtros: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
    empresaSelecionada: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
    tipoResumo: function () {
      this.carregar();
    },
  },
  computed: {},
  methods: {
    async carregar() {
      this.loading = true;
      try {
        let result = await PedidoLib.getProdutosVendidos(
          moment(this.dateRange.start).format("YYYY-MM-DD"),
          moment(this.dateRange.end).format("YYYY-MM-DD"),
          this.empresaSelecionada,
          this.filtros.tipo_entrega?this.filtros.tipo_entrega:null,
          this.filtros.tipo_preco,
          this.filtros.produtos
        );
        console.log("result", result);
        this.list = result.data;
        console.log(agrupador);
        // this.resumo = agrupador(
        //   this.list,
        //   "Produto",
        //   "qtd",
        //   "vl_unitario"
        // ).sort((a, b) => (a.prod < b.prod ? -1 : 1));
        // this.tiposPrecoHave = agrupador(this.list, "tipo", "qtd", "vl_unitario")
        //   .sort((a, b) => (a.tipo > b.tipo ? 1 : -1))
        //   .sort((a, b) => (!a.tipo || !b.tipo ? -1 : 1));
        // this.tiposEntregaHave = agrupador(
        //   this.list,
        //   "tipo_entrega",
        //   "qtd",
        //   "vl_unitario"
        // ).sort((a, b) => (a.tipo_entrega > b.tipo_entrega ? 1 : -1));
        console.log("res", this.resumo);
      } catch (e) {
        console.log("erro ao carregar", e);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>