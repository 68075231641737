var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('b-form-group',{attrs:{"label":"Tipo de Entrega"}},[_c('v-select',{attrs:{"options":['BALCAO', 'BUSCAR', 'ENTREGA','MESA'],"multiple":"","reduce":(it) => it.toUpperCase()},model:{value:(_vm.filtros.tipo_entrega),callback:function ($$v) {_vm.$set(_vm.filtros, "tipo_entrega", $$v)},expression:"filtros.tipo_entrega"}})],1)],1),_c('div',{staticClass:"col-3"},[_c('b-form-group',{attrs:{"label":"Tipo de Preço"}},[_c('v-select',{attrs:{"options":_vm.tiposPreco,"label":"tipo","multiple":"","reduce":(it) => it.cod_tipo},model:{value:(_vm.filtros.tipo_preco),callback:function ($$v) {_vm.$set(_vm.filtros, "tipo_preco", $$v)},expression:"filtros.tipo_preco"}})],1)],1),_c('div',{staticClass:"col-3"},[_c('b-form-group',{attrs:{"label":"Produto"}},[_c('v-select',{attrs:{"options":_vm.produtos,"label":"nome","multiple":"","reduce":(it) => it.cod_produto},model:{value:(_vm.filtros.produtos),callback:function ($$v) {_vm.$set(_vm.filtros, "produtos", $$v)},expression:"filtros.produtos"}})],1)],1),_c('div',{staticClass:"col-3"},[_c('b-form-group',{attrs:{"label":"Tipo de Resumo"}},[_c('b-select',{attrs:{"options":[
            { text: 'Quantidade', value: 'qtd' },
            { text: 'Valor', value: 'vl_unitario' },
          ]},model:{value:(_vm.tipoResumo),callback:function ($$v) {_vm.tipoResumo=$$v},expression:"tipoResumo"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('hr'),_c('pivot',{attrs:{"data":_vm.list,"default-show-settings":false,"hide-settings-text":"Fechar Configurações","show-settings-text":"Configurações","no-data-warning-text":"Nenhum produto encontrado","fields":_vm.campos,"available-field-keys":[
          'GrupoProduto',
          'Dia',
          'Mes',
          'Ano',
          'Empresa',
          'Tamanho',
        ],"row-field-keys":['Produto'],"col-field-keys":['Entrega'],"reducer":(sum, item) => sum + item[_vm.tipoResumo],"reducer-initial-value":0,"loading":_vm.loading,"computing":_vm.loading,"showFooter":true,"is-data-loading":_vm.loading,"rows-label-text":"Linhas","cols-label-text":"Colunas","available-fields-label-text":"Campos Disponíveis"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }